<template>
  <div class="row">
    <div class="col-lg-12">
      <table class="table table-bordered" v-if="!showDetails">
        <thead class="thead-light">
        <tr>
          <th></th>
          <th width="20px">СР</th>
          <th width="20px">ЧТ</th>
          <th width="20px" v-if="quarter === 4">Гд</th>
          <th width="20px" v-if="quarter === 4">Экз</th>
          <th width="20px" v-if="quarter === 4">Итог</th>
          
        </tr>
        </thead>
        <tbody>
        <tr class="subject-item" v-for="subject in subjects" :key="subject.subjectId">
          <td>
            <span class="subject-name" @click="openSubjectDetails(subject)">{{subject.subjectName}}</span> - <span class="teacher-name">{{subject.teacherName}}</span>
            <div>
              <span v-for="lesson in subject.lessons" :key="lesson.lessonId" class="mark-item">
                {{ displayMark(lesson.mark)}}
              </span>
            </div>
          </td>
          <td>{{subject.averageMarkString}}</td>
          <td>{{displayQuarterMark(subject.quarterMark)}}</td>
          <td v-if="quarter === 4">{{displayQuarterMark(subject.annualMark)}}</td>
          <td v-if="quarter === 4">{{displayQuarterMark(subject.examinationMark)}}</td>
          <td v-if="quarter === 4">{{displayQuarterMark(subject.finalMark)}}</td>
        </tr>
        </tbody>
      </table>
      
      <div v-if="showDetails">
        <div class="pull-right">
          <button class="btn btn-icon btn-danger">
            <i class="fa fa-window-close" @click="closeDetails"></i>
          </button>
        </div>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td class="subject-name">Предмет</td>
            <td>{{currentSubject.subjectName}}</td>
          </tr>  
          <tr>
            <td>Учитель</td>
            <td class="teacher-name">{{currentSubject.teacherName}}</td>
          </tr>
          <tr>
            <td>Средняя оценка</td>
            <td>{{currentSubject.averageMarkString}}</td>
          </tr>  
          <tr>
            <td>Четвертная оценка</td>
            <td>{{displayMark(currentSubject.quarterMark)}}</td>
          </tr>
          <tr v-if="quarter === 4">
            <td>Годова оценка</td>
            <td>{{displayMark(currentSubject.annualMark)}}</td>
          </tr>    
          <tr v-if="quarter === 4">
            <td>Экзаменационная оценка</td>
            <td>{{displayMark(currentSubject.examinationMark)}}</td>
          </tr> 
          <tr v-if="quarter === 4">
            <td>Итоговая оценка</td>
            <td>{{displayMark(currentSubject.finalMark)}}</td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th width="20%">Дата</th>
            <th width="*">Домашнее задание</th>
            <th width="20px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="lesson in currentSubject.lessons" :key="lesson.lessonId">
            <td>{{lesson.dateSting}}</td>
            <td>{{lesson.homeWork}}</td>
            <td>{{displayMark(lesson.mark)}}</td>
          </tr>
          </tbody>
        </table>
      </div>      
    </div>
  </div>
</template>
<style>
.subject-item{
  
}
.subject-name{
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.teacher-name{
  font-style: italic;
}

.mark-item{
  display: inline-block;
  padding-right: 10px;
  font-weight: bold;
  color: blue;
}
</style>
<script>
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "StudentMarksByQuarter",  
  components: {
    
  },
  props:{
    studentId:String,
    quarter:Number,
    mode:String,
  },
  data() {
    return {
      marksByQuarter:{},
      subjects:[],
      showDetails: false,
      currentSubject: {
        subjectName: '',
        teacherName: '',
        averageMarkString: '',
        lessons:[]
      },
    };
  },
  mounted() {
   this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;
      let route = 'marks/getStudentMarksByQuarter';
      let params = {studentId: this.studentId, quarter: this.quarter};
      if(this.mode === 'student'){
        route = 'marks/getStudentCurrentSubjectsByQuarter'
        params = {quarter: this.quarter};
      }
      
      ApiService.querySecured(route, {
        params: params
      })
          .then(({data}) => {
            $this.marksByQuarter = data;
            $this.subjects = data.subjects;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    displayMark(mark){
      return DictionariesService.displayMark(mark);
    },

    openSubjectDetails(subject){
      this.currentSubject = subject;
      this.showDetails = true;
    },
    closeDetails(){
      this.showDetails = false;
    },
    displayQuarterMark(mark){
      return DictionariesService.displayQuarterMark(mark);
    }
  }
};
</script>
